export const attachImageEdgesRecursive = function(imageEdges, object){

    let keys = Object.keys(object)

    keys.forEach(key => {

        if (key === "image") {

            let image = imageEdges.find(n => {
                return n.node.relativePath.includes(object[key]);
            })

            object.imageNode = image?.node
            return

        }

        if (typeof object[key] === "object" && object[key]){
            attachImageEdgesRecursive(imageEdges, object[key])
        }

    })

}